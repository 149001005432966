<template>
  <case-view v-bind:dataTypeName="'aragDataType'" v-bind:insurance="'ARAG'" v-bind:permission="'ErstberatungVersicherungspruefung'" v-bind:rebindPermission="'ErstberatungVersicherungspruefung'" v-bind:dataType="'ErstberatungVersicherungspruefung'" v-bind:name="'Versicherungsprüfungen'" v-bind:controllerURL="controllerURL" v-bind:allowDataEdit="false"/>
</template>

<script>
import CaseView from "@/components/Case/CaseView";
import {getApiURL} from "@/configBuilder";
export default {
  name: "ErstberatungVersicherungspruefungView",
  components: {CaseView},
  data: () => ({
    controllerURL: getApiURL("AragData")
  }),
}
</script>

<style scoped>

</style>
